html {
  transition: font-size 200ms;
}
@media screen and (max-width: 550px) {
  html {
    font-size: 14px;
  }
}

body {
  color: #fff;
  font-family: "Resident Grotesk", Helvetica, Arial, sans-serif;
  overflow: hidden;
  background: #000;
}
::selection {
  background-color: rgba(255, 255, 255, 0.2);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2 {
  font-weight: normal;
}
h2 {
  transition: transform 800ms cubic-bezier(0.25, 1, 0.5, 1);
}
h2.l {
  transform: translateY(1.5rem);
}

main {
  /* min-height: 100vh; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* SERVICES */
main ul {
  list-style-type: none;
  display: flex;
  font-size: 1.25rem;
  margin-top: 2rem;
  user-select: none;
  transition: transform 800ms cubic-bezier(0.25, 1, 0.5, 1) 50ms;
}
main ul.l {
  transform: translateY(1.5rem);
}
main ul li:not(:last-child) {
  margin-right: 3rem;
  position: relative;
}
main ul li a {
  text-decoration: none;
  color: inherit;
}
main ul li:not(:last-child)::after {
  content: "";
  position: absolute;
  background-image: url(../assets/dash_animal.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 1rem;
  height: 0.5rem;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1rem;
}
@media screen and (max-width: 550px) {
  main ul li:not(:last-child) {
    margin-right: 1.5rem;
  }
  main ul li:not(:last-child)::after {
    width: 1rem;
    height: 0.5rem;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 0.3rem;
  }
}
@media screen and (max-width: 390px) {
  main ul {
    flex-direction: column;
    align-items: center;
  }
  main ul li:not(:last-child) {
    margin-right: initial;
    margin-bottom: 1.5rem;
  }
  main ul li:not(:last-child)::after {
    left: calc(50% - 0.5rem);
    top: calc(100% + 0.75rem);
  }
}
video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0;
  transition: opacity 250ms;
}

/* LOGO */
.logo__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.logo__wrapper img {
  margin-bottom: 0.5rem;
  width: 18.75rem;
  pointer-events: none;
}
.logo__wrapper h2 {
  font-size: 1.25rem;
  text-align: center;
  max-width: 25ch;
}

/* FOOTER */
.footer {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .footer {
    bottom: 4rem;
  }
}
.footer__icon {
  width: 4.375rem;
  height: auto;
  margin-bottom: 1.25rem;
  pointer-events: none;
  user-select: none;
}
.footer__links {
  display: flex;
  /* user-select: none; */
}
.footer__links a {
  text-decoration: none;
  color: inherit;
}
.footer__links a:not(:last-of-type) {
  margin-right: 1rem;
}
.credits {
  font-size: 0.625rem;
  opacity: 0.5;
  position: absolute;
  bottom: 2.15rem;
  right: 4rem;
}
@media screen and (max-width: 767px) {
  .credits {
    font-size: 0.75rem;
    transform: translateX(50%);
    right: 50%;
  }
}
@media screen and (max-width: 550px) {
  .credits {
    font-size: 0.75rem;
  }
}

/* ICONS */
.bi {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  fill: currentColor;
  position: absolute;
  bottom: 1.9rem;
  right: 2rem;
}
@media screen and (max-width: 767px) {
  .bi {
    bottom: 2rem;
  }
}
.bi-volume-mute-fill {
  opacity: 0.5;
}
.bi-volume-up-fill {
  display: none;
}
